import * as keys from './key.config';
const base = {
	ossHost: 'https://static.yudada.com/iotProductTool/',
	ossHostPond: 'https://static.yudada.com/wechat/pond/',
	RETRY_COUNT: 3,
	RETRY_DELAY: 3000,
	BASE_URL: process.env.VUE_APP_BASE_URL,
	ENV: process.env,
	PROBE_BASE_URL: process.env.NODE_ENV == 'production' ? `${process.env.VUE_APP_BASE_URL}qcTool/` : 'https://t.iot.tool.yudada.com/app/',
	// PROBE_BASE_URL: process.env.NODE_ENV == "production" ? `${process.env.VUE_APP_BASE_URL}qcTool/` : 'http://127.0.0.1:8085/',
	ADMIN_URL: process.env.NODE_ENV == 'production' ? `https://lansong.admin.yudada.com/` : 'https://t.lansong.admin.yudada.com/',
	DEALER_WECHAT_BASE_URL: process.env.NODE_ENV == 'production' ? 'https://mp.yudada.com/dealer/' : 'https://t.mp.yudada.com/dealer/',
	MP_WECHAT_BASE_URL: process.env.NODE_ENV == 'production' ? 'https://mp.iot.yudada.com/' : 'https://uat.mp.iot.yudada.com/',
	// ADMIN_URL: process.env.NODE_ENV == "production" ? `https://lansong.admin.yudada.com/` : 'http://127.0.0.1:3011/',
	fList: ['yingwo', 'lexing', 'ruiyin', 'wangbo'],
	password: 'VQPApqe4JxK2qCrp',
	ADMIN_TOKEN: process.env.NODE_ENV == 'production' ? 'd47454b3f6d948aeb10239bf49169f45' : 'a8919f8923f0472baee23f50ef3f2916',
	oxygenValList: {
		0: 14.64,
		1: 14.22,
		2: 13.82,
		3: 13.44,
		4: 13.09,
		5: 12.74,
		6: 12.42,
		7: 12.11,
		8: 11.81,
		9: 11.53,
		10: 11.26,
		11: 11.01,
		12: 10.77,
		13: 10.53,
		14: 10.3,
		15: 10.08,
		16: 9.86,
		17: 9.66,
		18: 9.46,
		19: 9.27,
		20: 9.08,
		21: 8.9,
		22: 8.73,
		23: 8.57,
		24: 8.41,
		25: 8.25,
		26: 8.11,
		27: 7.96,
		28: 7.82,
		29: 7.69,
		30: 7.56,
		31: 7.43,
		32: 7.3,
		33: 7.18,
		34: 7.07,
		35: 6.95,
	},
};
export { base, keys };
